import classNames from 'classnames';
import React from 'react';

import styles from './DrawerToMenu.module.scss';
import useMediaQuery from '../../utils/useMediaQuery';
import loadable from '@loadable/component';

const Drawer = loadable(() => import('../Drawer/Drawer'));

export const DrawerContent = ({ children, direction, isNested = false, trigger, onOverlayClick }) => {
  const { isLowerThan, BREAKPOINTS } = useMediaQuery();
  const isNotDesktop = isLowerThan(BREAKPOINTS.EXTRA_LARGE);

  return isNotDesktop ? (
    <Drawer
      key={isLowerThan(BREAKPOINTS.EXTRA_LARGE)}
      direction={direction}
      trigger={trigger}
      onOverlayClick={onOverlayClick}
      isNested
    >
      {children}
    </Drawer>
  ) : null;
};

export const MenuContent = ({ children, shouldBeOpen, classList = {}, automationId }) => {
  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery();
  const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);
  return isDesktop ? (
    <div
      className={classNames(styles.menu, classList.root, { [styles.visible]: shouldBeOpen })}
      data-testid={automationId}
    >
      {children}
    </div>
  ) : null;
};

DrawerContent.displayName = 'DrawerContent';
export default React.memo(function DrawerToMenu({ children }) {
  return children;
});
